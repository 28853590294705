/* eslint-disable import/first */
import React, { useState } from "react";
import { get, isEmpty } from "lodash";
import { isSold } from '../../property/utils';

import {
    withSubtheme,
    // AddressFormat
} from '../../../../StarberryComponentsMui';

import ContactModuleComp from "../../pages/my-property/common/contactModule";
import PropertyFullAddress from "../../property/components/address/fullAddress";
import CurrencyFormat from '../../PropertyResult/CurrencyFormat';
import { useMyaccountState, useAuthState, PropertyActionStoreProvider, usePropertyActionState } from "../../../../services";

import CarouselImages from '../../property/components/images';
import FloorplanImages from '../../property/components/floorplans';
import EpcImages from '../../property/components/epc';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import Tabs from '../../tabs';
import PageTitle from '../../pageTitle';
import Rooms from '../../property/components/rooms';
import PropertyMap from "../../property/components/map";
import SaveProperty from '../../PropertyResult/SavePropertyWrap';
import FloatingContainer from '../../floatingContainer';
import BookViewing from '../../PropertyResult/BookViewing';
import HomeReport from '../../property/components/homeReport';
import RoomDetails from '../../property/components/roomDetails';
import MakeAnOffer from '../../PropertyResult/MakeAnOffer';
import CreateTenancy from '../../PropertyResult/CreateTenancy';
import HTMLReactParser from 'html-react-parser';
import SocialShare from '../../socialShare';
import BackButton from '../../backButton';
import Hidden from '@mui/material/Hidden';

import defaults from './defaults';
import PropertyDetailsSkeleton from "./propertyDetailsSkeleton"


const HtmlText = (props) => {
    return HTMLReactParser(props.html.replace(/\n/g,'<br />'))
}

const ReadMoreContent = (props) => {
    const { html } = props;
    const maxString = 400;
    const maxWord = 30;
    const enableReadMore = (html.length > maxString)
    const [isReadMore, setIsReadMore] = useState(enableReadMore);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    let readMoreText = ' read less';
    let readMoreClass = ' read-less';
    if (isReadMore) {
        readMoreText = ' read more';
        readMoreClass = ' read-more';
    }
    let text = html
    if (isReadMore) {
        text = html.split(/\s+/).slice(0, maxWord).join(" ");
    }

    return(
        <>
            <HtmlText html={text} />
            {enableReadMore && (<a className={`read-more-read-less ${readMoreClass}`} onClick={toggleReadMore}>{isReadMore && (<>...</>)}<span>{readMoreText}</span></a>)}
        </>
    )
}

const MakeAnOfferWrap = React.memo(({property}) => {
    const { state, services } = usePropertyActionState();
    const hasAppointments = state.hasAppointments || false;

    React.useEffect(() => {
        services.hasAppointments({property_id:property.crm_id});
    }, [property]) // eslint-disable-line react-hooks/exhaustive-deps

    if (hasAppointments) {
        if (property.search_type === `lettings`)
            return <CreateTenancy property={property} />

        return <MakeAnOffer property={property} />
    }

    return <></>
})

const SocialShareComp = ({property, shareUrl, format}) => {
        const address = property.address
        const pageTitle = format.map(x=>address[x]).filter(x=>x).join(', ');
        const pageUrl = shareUrl(property)
    return (
        <SocialShare title="Share Property" property={property} pageUrl={pageUrl} pageTitle={pageTitle} />
    )
}

const OverviewContent = (props) => {
    const { property } = props

    let moduleTitle = 'Contact your agent';
    const agentRole = get(property, 'letting_info.agentRole', false)
    if (property.search_type === 'lettings')
        moduleTitle = 'Contact your letting agent';
    if (agentRole && agentRole === 'managed')
        moduleTitle = 'Contact your property manager';
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} lg={8}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Hidden mdUp>
                            <ContactModuleComp
                                simpleContact={true}
                                data={{...props.property?.negotiator_info, helpTextLink: "Message me", helpText: "Need help? "}}
                            />
                        </Hidden>
                        {property?.introtext &&
                            <div className="propertyDetailsDescription">
                                <ReadMoreContent html={property.description} />
                            </div>
                        }
                        {property?.description &&
                            <div className="propertyDetailsDescription">
                                <ReadMoreContent html={property.description} />
                            </div>
                        }
                        <div className="propertyAgentNote" >
                            <p>Agent Note</p>
                            <ReadMoreContent html={
                                `<span><b>These property details</b> are set out as a general outline only and do not constitute any part of an Offer or Contract.<b> Any services</b>, equipment, fittings or central heating systems have not been tested and no warranty is given or implied that these are in working order. Buyers are advised to obtain verification from their solicitor or surveyor.
                                    <b> Fixtures &...</b>  All fixtures and fittings mentioned in these particulars are included in the sale. All others in the property are specifically excluded.
                                    <b> Photographs:</b> Photographs are reproduced for general information and it must not be inferred that any item is included for sale with the property.
                                    <b> Floor plans:</b>Whilst every attempt is made to ensure the accuracy of any floor plan, measurements of doors, windows, rooms and any other items are approximate and no responsibility is taken for any error, omission, or misstatement.The plan is for illustrative purposes only and should be used as such by any prospective purchaser.
                                </span>`
                            }
                            />
                        </div>

                        {(property?.latitude && property?.longitude) && (<PropertyMap latitude={property.latitude} longitude={property.longitude} />)}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} lg={4}>
                <FloatingContainer>
                    {!isSold(props?.flagStatus, property.status) && (
                        <BookViewing property={property} />
                    )}
                    {property?.home_report_url && <HomeReport property={property} />}
                    <PropertyActionStoreProvider>
                        <MakeAnOfferWrap property={property} />
                    </PropertyActionStoreProvider>
                </FloatingContainer>
                <Hidden mdDown>
                    <ContactModuleComp
                        data={property?.negotiator_info}
                        moduleTitle={moduleTitle}
                    />
                </Hidden>
            </Grid>
        </Grid>
    )
}

// --------------------------------------------------------------------------
// MAIN TABS COMPONENT FOR DESKTOP AND MOBILE
const mainTabData = (props) => {
    const { openLightboxOnSource, openLightboxOnSourceEpc, goToMap, downloadBrochure, openRoomDetailsFun,...rest } = props
    const property = rest?.property;

    // const appointments = /*props.property?.appointments || */[];
    // const tenancies = props.property?.tenancies || [];
    const mapRef = React.createRef();
    let tabItems = [
        {
            "id": "tab1",
            "iconName": "overviewTabIcon",
            "name": "Overview",
            "subtitle": "Lorem ipsum dolor sit amet",
            "content": <OverviewContent {...rest} mapRef={mapRef} />,
        }
    ]

    if (!isEmpty(property?.floorplan)) {
        tabItems.push(
            {
                "id": "tab2",
                "iconName": "emailIcon",
                "name": "Floorplan",
                "subtitle": "Lorem ipsum dolor sit amet",
                // "content": ,
                "customOnClick": (event) => {
                    openLightboxOnSource(0);
                    event.stopPropagation();
                },
            },
        )
    }
    if (!isEmpty(property?.epc)) {
        tabItems.push(
            {
                "id": "tab3",
                "iconName": "offerIcon",
                "name": "EPC",
                "customOnClick": (event) => {
                    openLightboxOnSourceEpc(0);
                    event.stopPropagation();
                },
                // "content": <PropertyActionStoreProvider><OfferContent {...rest} /></PropertyActionStoreProvider>,
            }
        )
    }
    if (!isEmpty(property?.room_details)) {
        tabItems.push(
            {
                "id": "tab3",
                "iconName": "offerIcon",
                "name": "Room Details",
                "customOnClick": () => openRoomDetailsFun(true),
                // "content": <RoomDetails property={property} setValue={props?.setValue} openModal={true} />,
            }
        )
    }
    // {property?.room_details && <RoomDetails property={property} />}
    if (property?.brochure && property.brochure.length) {
        tabItems.push(
            {
                "id": "tab6",
                "iconName": "documentsIcon",
                "name": "PDF Brochure",
                "customOnClick": () => downloadBrochure(),
            }
        )
    }

    return tabItems;
}
const MainTabsComp = (props) => {
    return(
        <Tabs
            data={mainTabData(props)}
            // property={property}
            manageValue={props?.manageValue}
            // lightboxFloorplans={lightboxFloorplans}
            // setLightboxFloorplans={setLightboxFloorplans}
            // openLightboxFloorplans={openLightboxFloorplans}
        />
    )
}

// END MAIN TABS COMPONENT FOR DESKTOP AND MOBILE
// --------------------------------------------------------------------------

// const MenuListTabsComp = (props) => {
//     const origData = mainTabData(props);
//     // Create a new array with only the id and name properties, we don't need the rest for the menus
//     const newData = origData.map(({content, ...keepData}) => keepData)

//     return(
//         <MenuListTabs
//             title={props.title && props.title}
//             data={newData}
//             manageValue={props.manageValue}
//             manageHandleChange={props.manageHandleChange}
//         />
//     )
// }

// const RightDrawerContent = ({props}) => {
//     return(
//         <MenuListTabsComp
//             data={mainTabData(props)}
//             property={props.property}
//             manageValue={props.manageValue}
//             manageHandleChange={props.manageHandleChange}
//         />
//     )
// }

const PageContent = React.memo(({property, flagStatus, ...rest}) => {

    const goToMap = '';
    const downloadBrochure = ''
    const { services:authServices } = useAuthState();
    const [value, setValue] = React.useState("tab1");
    const [openRoomDetails, setOpenRoomDetails] = React.useState(false);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function openLightboxOnSource(number) {
        setValue('tab1')
        setLightboxFloorplans({
            toggler: !lightboxFloorplans.toggler,
            sourceIndex: number
        });
    }

    function openLightboxOnSourceEpc(number) {
        setValue('tab1')
        setLightboxEpcs({
            toggler: !lightboxEpcs.toggler,
            sourceIndex: number
        });
    }

    function openRoomDetailsFun(val) {
        setValue('tab1')
        setOpenRoomDetails(val)
    }

    React.useEffect(() => {
        const pageRightDrawer = <>
            {!isSold(flagStatus, property?.status) && (
                <SaveProperty property={property} type="property" />
            )}
        </>
        // <RightDrawerContent
        //     props={{...rest, /*theme:theme,*/property: property, manageValue:value, manageHandleChange:handleChange}}
        // />

        authServices.updateState({pageTitle:property?.display_address, pageRightCustomComponent:pageRightDrawer, pageLeftIcon: true});
    },[property, value]) // eslint-disable-line react-hooks/exhaustive-deps

    // Lightbox Floorplans open/close state
    const [lightboxFloorplans, setLightboxFloorplans] = React.useState({
        toggler: false,
        sourceIndex: 0
    });

    const [lightboxEpcs, setLightboxEpcs] = React.useState({
        toggler: false,
        sourceIndex: 0
    });

    return(
        <>
            <Grid item xs={12}>
                <MainTabsComp
                    property={property}
                    manageValue={value}
                    setValue={setValue}
                    manageHandleChange={handleChange}
                    openLightboxOnSource={openLightboxOnSource}
                    openLightboxOnSourceEpc={openLightboxOnSourceEpc}
                    openRoomDetailsFun={openRoomDetailsFun}
                    goToMap={goToMap}
                    downloadBrochure={downloadBrochure}
                    flagStatus={flagStatus}
                    // setLightboxFloorplans={setLightboxFloorplans}
                />
            </Grid>
            <FloorplanImages property={property} lightboxFloorplans={lightboxFloorplans} />
            <EpcImages property={property} lightboxEpcs={lightboxEpcs} />
            <RoomDetails property={property} openRoomDetailsFun={openRoomDetailsFun} openRoomDetails={openRoomDetails} setOpenRoomDetails={setOpenRoomDetails} />
        </>
    )
})

const PropertyDetailWrapper = withSubtheme(props => {

    const {
        id,
        className,
        shareUrl,
        format,
        // ...rest
    } = props

    const { state, services } = useMyaccountState();
    const propertyData =  state?.properties || {};
    const property = get(propertyData, '0'); // get the first element

    React.useEffect(() => {
        services.getProperties({id:id})
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container maxWidth="xl" classes={{root: className}}>
            <Grid container spacing={1}>
                {!propertyData.length ?
                    <Grid item xs={12}>
                        <PropertyDetailsSkeleton />
                    </Grid>
                :
                    <>
                        <Grid item xs={12}>
                            <CarouselImages property={property} showSavedProperty={true} flagStatus={props?.flagStatus} />
                        </Grid>
                        {property?.display_address &&
                            <Grid item xs={12}>
                                <BackButton />
                                <PageTitle>
                                    <PropertyFullAddress property={property} format={format} />
                                    {!isSold(props?.flagStatus, property?.status) && (
                                        <SaveProperty property={property} type="property" />
                                    )}
                                </PageTitle>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            {!!property?.price &&
                                <Typography classes={{root: "propertyDetailsPrice"}}>
                                    <CurrencyFormat
                                        value={property?.price ? property.price : 0}
                                        sector={property?.sector}
                                        search_type={property?.search_type}
                                        price_qualifier={`${property?.price_qualifier || ''} `}
                                    />
                                </Typography>
                            }
                            <Rooms
                                property={property}
                            />
                            <SocialShareComp property={property} shareUrl={shareUrl} format={format}  />
                        </Grid>
                        <PageContent
                            property={property}
                            flagStatus={props?.flagStatus}
                        />
                    </>
                }
            </Grid>
        </Container>
    )
}, 'propertyDetailsPage', defaults)

export default PropertyDetailWrapper
