export default {
    "sx": (theme) => ({
        "height": "400px",
        "marginTop": theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            "height": "600px",
        },
        "& iframe": {
            "width": "100%",
            "height": "100%",
        },
    }),
}