export default {
    "props": {
        "showFacebook": true,
        "showTwitter": true,
        "showLinkedin": true,
        "iconButtonProps": {
            "color": "inherit",
            "disableRipple": true,
        },
    },
    "sx": (theme) => ({
        "& .socialShareContainer": {
            "& .socialShareIconButton": {
                "padding": 0,
                "paddingRight": theme.spacing(3),
                "&:last-child": {
                    "paddingRight": 0,
                },
            },
        }
    }),
}