export default {
    "props": {
        "containerProps": {
            "container": true,
            // "spacing": 1,
            "py": ["0","7px"],
        },
        "gridItemProps": {
            "item": true,
            "xs": 6,
            "padding": "0 5px"
        }
    },
    "sx": (theme) => ({
        "position": "relative",
        // "& .item-title": {
        //     "padding": "5px 0",
        // }
    })
}
