export default {
    "props": {
        "btnLabelProps": "Room Details",
        "maxDialogW": "sm",
        "btnProps": {
            "color": "primary",
            "variant": "contained"
        }
    }
}
