import React from 'react';
import Stack from "@mui/material/Stack"
import Skeleton from "@mui/material/Skeleton"
import range from 'lodash/range'
import TabContainerSkeleton from "../../../../StarberryComponentsMui/components/Skeleton/TabContainerSkeleton"
import TabSkeleton from "../../../../StarberryComponentsMui/components/Skeleton/TabSkeleton"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"

const PropertyDetailsSkeleton = () => {

    return (
        <Stack direction="column" spacing={4}>
            <Skeleton sx={{mb: 4}} variant="rectangular" width="100%" height={550} />
            <Stack direction="column" spacing={1}>
                <Skeleton variant="text" width="75%" height={50} />
                <Skeleton variant="text" width="65%" height={25} />
                <Skeleton variant="text" width="27%" height={20} />
            </Stack>
            <Stack direction="row" spacing={4}>
                {range(0, 3).map(index => (
                    <Skeleton
                        key={index}
                        variant="text"
                        width={80}
                        height={24}
                    />
                ))}
            </Stack>
            <Stack direction="column" spacing={2}>
                <Skeleton variant="text" width={150} height={56} />
                <Stack direction="row" spacing={4}>
                    {range(0, 3).map(index => (
                        <Skeleton
                            key={index}
                            variant="rectangular"
                            width={17}
                            height={17}
                        />
                    ))}
                </Stack>
            </Stack>
            <Box>
                <TabContainerSkeleton>
                    {range(0, 3).map(index => (
                        <TabSkeleton key={index} />
                    ))}
                </TabContainerSkeleton>
                <Box my={4}>
                    <Grid container spacing={4}>
                        <Grid xs={12} lg={8} item >
                            <Stack direction="column" spacing={2}>
                                <Skeleton variant="rectangular" width="100%" height={100} />
                                <Skeleton variant="rectangular" width="100%" height={500} />
                            </Stack>
                        </Grid>
                        <Grid xs={12} lg={4} item>
                            <Stack direction="column" spacing={2}>
                                <Skeleton sx={{borderRadius: 16}} variant="rectangular" width="100%" height={50} />
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Stack>
    );
};

export default PropertyDetailsSkeleton;
