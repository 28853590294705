export default {
    "props": {

    },
    "sx": (theme) => ({
        "& .propertyDetailsPrice": {
            "fontSize": theme.bodySmall,
            "marginBottom": theme.spacing(2.25),
            "color": theme.palette.primary.main,
            [theme.breakpoints.up('sm')]: {
                "fontSize": theme.bodyMedium,
            },
        },
        "& .propertyDetailsDescription": {
            "marginBottom": theme.spacing(3.25),
            "fontSize": theme.bodyMedium,
            "lineHeight": "28px",
            "& > p": {
                "marginBottom": theme.spacing(3.25),
                "fontSize": theme.bodyMedium,
                "lineHeight": "28px",
            },
        },
        " & .propertyAgentNote":{
            "marginBottom": theme.spacing(3.25),
            "fontSize": theme.bodyMedium,
            "lineHeight": "20px",
            "& > span": {
                "marginBottom": theme.spacing(3.25),
                "fontSize": "0.7rem",
                "lineHeight": "20px",
            },
        },
        "& .read-more-read-less": {
            "whiteSpace": "nowrap",
            "cursor": "pointer"
        },
        "& .read-more-read-less span": {
            "color": theme.palette.primary.main
        },
        "& .propertyDetailsFlag": {
            "color": "red"
        },
    }),
}
