import React from 'react';
import FsLightbox from 'fslightbox-react';
import { isEmpty } from 'lodash';
import ModalPortal from '../../../modals/ModalPortal';

const PropertyEpcs = props => {
    const {
        property,
        lightboxEpcs,
        // setLightboxEpcs,
    } = props;

    if (isEmpty(property.epc))
        return <></>

    const imgLightbox = [...property.epc]
    const arrImgs = imgLightbox.map((img) => {
        return img.url
    })

    return(
        <ModalPortal>
            <FsLightbox
                toggler={lightboxEpcs.toggler}
                type="image"
                sourceIndex={lightboxEpcs.sourceIndex}
                sources={arrImgs}
                // openOnMount={true}
            />
        </ModalPortal>
    )
}

export default PropertyEpcs
