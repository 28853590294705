import React from 'react'
import AddressFormat from "../../../PropertyResult/AddressFormat";

const PropertyFullAddress = ({property, format}) => {
    const addrFormat = {"keys": (format ||["house_name", "house_number", "street_name", "area", "town", "postcode"])}
    return (
        <AddressFormat property={{...property, ...property.address}} format={addrFormat} />
    )
}

export default PropertyFullAddress;
