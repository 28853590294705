import React from 'react';

export const markerImageSrc = "/images/marker.png";

const MapMarkerImage = (props) => {
    let markerImage = props.src || markerImageSrc;
    let altText = props.alt || "marker";
    let showFlag = 1;
    if ( 0 === props.show ) {
      showFlag = 0;
    }

    if (typeof window !== 'undefined') {
      const baseUrl = window.location.origin;
      if (markerImage.toLowerCase().indexOf(baseUrl) === -1) { // if baseUrl not already added ...
        markerImage = baseUrl + markerImage; // ... add it
      }
    }

    let _markerImage = null;
    if (showFlag) {
        _markerImage = <img src={markerImage} alt={altText} />
    }
    
    return _markerImage;
}

export default MapMarkerImage;
