import { useEffect } from 'react';

export default function LoadExternalScript(props) {
    const _src = props.src || '';
    const _async = props.async || '';
    const _defer = props.defer || '';
    const _loadScript = props.loadScript || '';
    const _appendScriptTo = props.appendScriptTo || 'body'; // 'head / 'body' => default 'body'

    // Ref: https://medium.com/better-programming/4-ways-of-adding-external-js-files-in-reactjs-823f85de3668
    // Ref: https://usehooks.com/useScript/
    useEffect(
        () => {

            if (!_src) {
                return;
            }

            let _document = document.body;
            if ( 'head' === _appendScriptTo ) {
                _document = document.head || document.getElementsByTagName('head').item(0);
            }

            // Fetch existing script element by src
            // It may have been added by another intance of this hook
            let script = document.querySelector(`script[src="${_src}"]`);
            
            if (!script) {
                script = document.createElement("script"); // Create script
                script.src = _src;
                if (_async !== '') {
                    script.async = _async;
                }
                if (_defer !== '') {
                    script.defer = _defer;
                }
                _document.appendChild(script); // Add script to document head/body
                if (_loadScript) {
                    script.onload = _loadScript;
                }
            }

            // Clean up
            return () => {
                if (script) {
                    _document.removeChild(script); // Remove script from document head/body
                }
            };
      },
      [_src,_defer,_appendScriptTo,_async,_loadScript] // Only re-run effect if script src changes
    );

    // export default function should return something,
    // otherwise, it will throw error as like the below,
    // "Nothing was returned from render.
    // This usually means a return statement is missing.
    // Or, to render nothing, return null"
    return null;

}
