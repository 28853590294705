import React from 'react';
import ReactDOMServer from 'react-dom/server';
import MapMarkerImage from './map-marker-image';

const MapMarker = (props) => {

  let html = props.html || '';
  const _markerImageShowFlag = props.markerImageShowFlag || 1;
  const _mapmarkerInfoBox = MapMarkerInfoBox(html, _markerImageShowFlag); 
  
  return (
    _mapmarkerInfoBox
  )
}
export default MapMarker;

export function MapMarkerInfoBox(html, flag) {
  const _html = html || '';
  const _flag = flag || 0;
  // let _styles = '';
  /*
  if ( 0 === _flag ) {
    _styles = {
      backgroundColor: "#ec008d",
      color: "#fff"
    }
  }
  */
  return ReactDOMServer.renderToStaticMarkup(
      <div className="marker" /*style={_styles}*/>
        <MapMarkerImage show={_flag} />
        <div className="info-box">
          {_html}
        </div>
      </div>
    )
}
