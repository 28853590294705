import * as React from 'react';
import { withSubtheme } from '../../../StarberryComponentsMui';
import { StarberryIcons } from '../icons';
import IconButton from '@mui/material/IconButton';
import ComponentLayout from '../ComponentLayout';

import defaults from './defaults';

const SocialShare = withSubtheme((props) => {
    const {
        className,
        title,
        moreLabel,
        moreLinkUrl,
        titleLinkMoreTooltipProps,

        showFacebook,
        showTwitter,
        showLinkedin,
        iconButtonProps,

        pageTitle,
        pageUrl
    } = props;

    const twitterPageUrl = `https://twitter.com/share?text=${pageTitle}&url=${pageUrl}`;
    const facebookPageUrl = `https://www.facebook.com/sharer/sharer.php?u=${pageUrl}&t=${pageTitle}`;
    const linkedinPageUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${pageUrl}&title=${pageTitle}`;


    // return empty if all chanels are disabled
    if (!showFacebook && !showTwitter && !showLinkedin) {
        return null
    }

    return(
        <ComponentLayout
            className={className}
            title={title}
            moreLabel={moreLabel}
            moreLinkUrl={moreLinkUrl}
            titleLinkMoreTooltipProps={titleLinkMoreTooltipProps}
        >
            <div className="socialShareContainer">
                {showTwitter &&
                    <IconButton aria-label="share on Twitter" {...iconButtonProps} classes={{root: "socialShareIconButton"}} onClick={() => window.open(twitterPageUrl, "_blank")}>
                        <StarberryIcons iconName="shareTwitter" />
                    </IconButton>
                }
                {showFacebook &&
                    <IconButton aria-label="share on Facebook" {...iconButtonProps} classes={{root: "socialShareIconButton"}} onClick={() => window.open(facebookPageUrl, "_blank")}>
                        <StarberryIcons iconName="shareFacebook" />
                    </IconButton>
                }
                {showLinkedin &&
                    <IconButton aria-label="share on LinkedIn" {...iconButtonProps} classes={{root: "socialShareIconButton"}} onClick={() => window.open(linkedinPageUrl, "_blank")}>
                        <StarberryIcons iconName="shareLinkedIn" />
                    </IconButton>
                }
            </div>
        </ComponentLayout>
    )
}, 'socialShare', defaults)

export default SocialShare
