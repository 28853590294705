import React, { useState } from "react";
import {
    withSubtheme
} from '../../../../../StarberryComponentsMui';
import LoadExternalScript from "../../../utils/load-external-script";
import { markerImageSrc } from "./map-marker-image";
import clsx from 'clsx';
import defaults from './defaultsLocratingMap';

// import { Container } from 'react-bootstrap';
/* TODO: To be moved to env. or config. file */
const locRatingScriptsJs = 'https://www.locrating.com/scripts/locratingIntegrationScripts.js';

const LocRatingMap = withSubtheme((props) => {
    const { className, data } = props
    let [activeCTA/* , setActiveCTA*/] = useState('location');
    let _data = data || "";
    let _mapClass = 'single'; // only one marker map
    if ( _data.length && _data.length > 1 ) { // more than one markers map
        _mapClass = 'all';
    }

    let _markerImage = markerImageSrc;
    const _iframeId = 'location-map';
    const _iframeTitle = "Location Map";

    const renderMap = (_activeCTA) => {

        if (_activeCTA) {
            activeCTA = _activeCTA;
        }

        let _type = '';
        switch (activeCTA) {
            case 'location':
            default:
                _type = 'transport';
            break;
            case 'schools':
                _type = 'schools';
            break;
            case 'local':
                _type = 'localinfo';
            break;
        }

        if (typeof window !== 'undefined') {
            const _baseUrl = window.location.origin;

            if (_markerImage.toLowerCase().indexOf(_baseUrl) === -1) { // if baseUrl not already added ...
                _markerImage = _baseUrl + _markerImage; // ... add it
            }
            if (typeof window.loadLocratingPlugin !== 'undefined') {
                try { // try
                    let _firstLat = '';
                    let _firstLng = '';
                    if (_data) {
                        if (_data[0]) {
                            if (_data[0].lat) {
                                _firstLat = _data[0].lat;
                            }
                            if (_data[0].lng) {
                                _firstLng = _data[0].lng;
                            }
                        }
                        let _firstMarkerImage = _markerImage;
                        if ( 'all' === _mapClass ) {
                            _firstMarkerImage = '.';
                        }
                        if ( 'single' === _mapClass ) {
                            _type = 'all';
                        }
                        window.loadLocratingPlugin ({
                            id: _iframeId,
                            lat: _firstLat,
                            lng : _firstLng,
                            type: _type,
                            icon: _firstMarkerImage,
                            onLoaded: function() {
                                let _n = 1;
                                for (let i in _data) {
                                    let _item = _data[i];
                                    let _markerId = 'marker-' + _n;
                                    let _lat = _item.lat;
                                    let _lng = _item.lng; 
                                    let _html = _item.html;
                                    if (typeof window.addLocratingMapMarker !== 'undefined') {
                                        window.addLocratingMapMarker(_iframeId,
                                        {
                                            id: _markerId, 
                                            lat: _lat, 
                                            lng: _lng,
                                            html: _html,
                                            icon: _markerImage,
                                            iconHeight: 68,
                                            iconWidth: 69
                                        });
                                    }
                                    _n++;
                                } // for
                                if (typeof window.centerLocratingMapUsingMarkers !== 'undefined') {
                                    window.centerLocratingMapUsingMarkers(_iframeId);
                                }
                            } // onLoaded: function()
                        }); // window.loadLocratingPlugin
                    } // if (data)
                } catch (err) {
                } // catch (err)
            } // if (typeof window.loadLocratingPlugin !== 'undefined')
        } // if (typeof window !== 'undefined')
    } // const renderMap = ()

    return (
        <div id="map-holder" className={clsx('map', className, _mapClass)}>
            {/* <h1>LocRating Map</h1> */}
            <LoadExternalScript 
              src={locRatingScriptsJs}
              // async="true"
              defer="true"
              loadScript={renderMap}
              // appendScriptTo="head"
            />
            <iframe
                id={_iframeId}
                title={_iframeTitle}>
            </iframe>
        </div>
    );
}, "locratingIframe", defaults);

export default LocRatingMap;