import React, {
    // useState
} from 'react';
import LocRatingMap from './locrating-map';
import { MapMarkerInfoBox } from './map-marker';

/**
 * Assets
 */

// import './styles/_index.scss';

/**
 * Components
 */

const PropertyMap = (props) => {
    // const [] = useState('')

    // Get Map Details
    let mapItems = [];
    let mapItem = {};
    mapItem['lat']  = props.latitude
    mapItem['lng']  = props.longitude
    const mapMarkerImageShowFlag = 0;
    let mapItemHtml = MapMarkerInfoBox("test", mapMarkerImageShowFlag);
    mapItem['html'] = mapItemHtml;
    mapItems.push( mapItem );

    return (
        <div className="property-map" id="property-map">
            <LocRatingMap data={mapItems} />
        </div>
    )
}

export default PropertyMap
